import axios from './http';
import i18n from './i18n';

async function bootstrap() {
    let en = await axios({method: 'get',  url: '/localization/en'});
    i18n.addResourceBundle('en', 'translation', en, true, true);
    window.ACCESS_TOKEN = (await axios({method: 'post', 'url': '/user/auth', data: window.Telegram.WebApp.initData || process.env.REACT_APP_DEFAULT_INIT_DATA})).access_token;
    return await axios({method: 'get', url: '/user/me'});
}

export default bootstrap;