import { useEffect, useState } from "react";

import bootstrap from './bootstrap';

function App() {
  let [loaded, setLoaded] = useState(false);
  let [loadFailed, setLoadFailed] = useState();
  let [profile, setProfile] = useState();
  useEffect(() => {
    bootstrap().then(setProfile).catch(setLoadFailed).finally(() => setLoaded(true));
  }, []);
  return (
    <div>
      {!loaded && <div>Loading...</div>}
      {loaded && loadFailed && <div>Failed: {loadFailed}</div>}
      {loaded && !loadFailed && <div>Profile: {JSON.stringify(profile)}</div>}
    </div>
  );
}

export default App;
